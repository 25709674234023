.image-container {
    position: relative;
    width: 100%;
    height: auto;
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: opacity 0.6s ease-in-out;
}

.image1 {
    opacity: 1;
}

.image2 {
    opacity: 0;
}

.image-container:hover .image1 {
    opacity: 0;
}

.image-container:hover .image2 {
    opacity: 1;
}
