:root {
    --color-primary: #004378;
    --color-primary-darker: #072446;
    --color-primary-lighter: #1670bf;
    --color-secondary: #ffc435;
    --color-secondary-darker: #b4880e;
    --color-secondary-lighter: #e2af23;
    --color-secondary-localLogic: #f0c150;
    --color-tertiary: #007f88;
    --color-tertiary-darker: #055d6b;
    --color-tertiary-lighter: #007f88;
    --color-tertiary-alt: rgba(254, 220, 8, 0.71);
    --color-quaternary: #f67575;
    --color-success: #0ead69;
    --color-inProgress: #ee4266;
    --color-canceled: #6103c2;
    --color-font: #111;
    --color-placeholder: #7a7e8d;

    --color-text: var(--color-primary);
    --color-text-fade: rgba(43, 63, 87, 0.74);
    --color-text-discret: lightgrey;
    --color-text-on-primary: #fff;
    --color-text-black: #000000;

    --color-primary-bg: #fff;
    --color-background-forground: #eff2f7;
    --color-tertiary-bg: #fafbfd;
    --color-tertiary-text: #92a2ba;
    --color-secondary-bg: #f2f7fa;
    --color-firstLvl-bg: #fff;
    --color-firstLvl-bg-dark: #e0e0e0;
    --color-secondLvl-bg: #f5f6fa;
    --color-secondLvl-dark-bg: #dce0e9;
    --color-thirdLvl-bg: #f5f6fa;
    --color-inactive-bg: #c4c4c4;

    --color-warning: var(--color-secondary);
    --color-error: #ff3e1d;
    --color-delete: #ff3e1d;

    --color-border: #c0c0c0;

    --color-header-bg: #f2f7fa;
    --color-header-font: #fff;
    --color-top-header-bg: #333;
    --color-top-header-font: white;
    --color-menu-header-bg: white;
    --color-menu-header-font: #333;
    --color-footer-bg: #f9f9fb;
    --color-footer-font: #f1f1f1;
    --nesto-colors-primary: #e31936;
    --nesto-colors-text-inverted: #ffffff;
    --color-promote-btn: var(--color-primary-darker);
    --color-promote-btn-border: var(--color-primary-darker);
    --color-grey-payment: #fafbfc;
    --color-grey-payment-secondary: #e7ecf1;
}
:root {
    --xs: 5px;
    --sm: 10px;
    --md: 20px;
    --lg: 50px;
    --xl: 100px;

    --bp-xl: 1280px;
    --bp-lg: 992px;
    --bp-md: 768px;
    --bp-sm: 576px;
    --bp-xs: 400px;

    --app-width: 1400px;

    --border-radius: 5px;
    --input-height: 36px;
    --letter-spacing-low: 3px;
    --letter-spacing-normal: 5px;
    --letter-spacing-huge: 8px;
    --border-radius-filters: 6px;
    --border-radius-see-more: 3px;

    --height-filters: 44px;
    --font-size-property-card: 10px;
    --border-radius-gallery-propertyPage: 2px;
    --font-weight-600: 600;
    --height-header: 54px;
    --height-headerTablet: 54px;
    --height-headerMobile: 54px;
    --height-map-header: 55px;
    --height-map-header-with-padding: 85px;
    --height-map-header-with-paddingTablet: 65px;
    --height-map-header-with-paddingMobile: 50px;
    --height-filtersSection: 88px;
    --height-filtersSectionTablet: 71px;
    --height-filtersSectionMobile: 69px;
}
.w-full {
    width: 100%;
}
.w-half {
    width: 50%;
}
.w-0 {
    width: 0;
}
.h-full {
    height: 100%;
}
:root {
    --zIndex-dashboard-side-column: 100;
    --zIndex-home-figures: 100;
    --zIndex-home-background: 100;
    --zIndex-dashboard-arrows: 200;
    --zIndex-product-card: 700;
    --zIndex-mobileMenu-cross: 725;
    --zIndex-date-picker-dashboard: 750;
    --zIndex-navBar: 800;
    --zIndex-top-navBar: 800;
    --zIndex-searchBar: 800;
    --zIndex-header: 810;
    --zIndex-overlayCompare: 950;
    --zIndex-modal: 1020;
    --zIndex-mobileMenu: 1030;
    --zIndex-overlay: 1100;
    --zIndex-virtualAssistant--popup: 1200;
    --zIndex-mesengerSwipeUp: 1360;
    --zIndex-mesengerIcon: 1360;
    --zIndex-cookieBanner: 1400;
    --zIndex-cookieBannerModal: 1500;
}
html,
body {
    padding: 0;
    margin: 0;
}
button:focus {
    outline: 0;
}
button:hover {
    cursor: pointer;
}
a:hover {
    cursor: pointer;
}
table {
    padding: 0;
    border-spacing: 0;
}
input {
    border: none;
}
input :focus {
        outline: 0;
    }
input,
label,
select,
button,
textarea {
    margin: 0;
    border: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    background: none;
}
/* Remove the stupid outer glow in Webkit */
input:focus {
    outline: 0;
}
/* Box Sizing Reset
-----------------------------------------------*/
/* All of our custom controls should be what we expect them to be */
input,
textarea {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
/* These elements are usually rendered a certain way by the browser */
button,
input[type="reset"],
input[type="button"],
input[type="submit"],
input[type="checkbox"],
input[type="radio"],
select {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
/* File Uploads
-----------------------------------------------*/
input[type="file"] {
}
/* Search Input
-----------------------------------------------*/
/* Make webkit render the search input like a normal text field */
input[type="search"] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: border-box;
}
/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
    display: none;
}
/* Buttons
-----------------------------------------------*/
button,
input[type="reset"],
input[type="button"],
input[type="submit"] {
    /* Fix IE7 display bug */
    overflow: visible;
    width: auto;
}
/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
    padding: 0;
    border: 0;
    background: none;
}
/* Textarea
-----------------------------------------------*/
textarea {
    /* Move the label to the top */
    vertical-align: top;

    /* Turn off scroll bars in IE unless needed */
    overflow: auto;
}
/* Selects
-----------------------------------------------*/
select {
}
select[multiple] {
    /* Move the label to the top */
    vertical-align: top;
}
.flex_center_center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.flex,
.flex_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.flex_rowReverse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
}
.flex_row_center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.flex_rowReverse_center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.flex_column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.flex_column_center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.flex_columnReverse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
}
.flex-1 {
    -webkit-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1;
}
.flex-2 {
    -webkit-box-flex: 2;
        -ms-flex: 2 1;
            flex: 2 1;
}
.flex-3 {
    -webkit-box-flex: 3;
        -ms-flex: 3 1;
            flex: 3 1;
}
.flexWrap_wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
}
.justifyContent_center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.justifyContent_flexEnd {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}
.justifyContent_spaceBetween {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.justifyContent_spaceAround {
    -ms-flex-pack: distribute;
        justify-content: space-around;
}
.alignItems_center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.alignItems_flexEnd {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
}
.flexShrink_0 {
    -ms-flex-negative: 0;
        flex-shrink: 0;
}
a.btn,
.btn {
    color: white;
    -font-weight: 600;
    padding: 10px 20px;
    background-color: #004378;
    background-color: var(--color-primary);
    border-radius: 3px 3px 3px 3px;
    cursor: pointer;
}
a.btn:focus,
a.btn:visited,
a.btn:active {
    color: white;
}
a.btn:hover,
.btn:hover {
    background-color: #072446;
    background-color: var(--color-primary-darker);
}
.btn_1 {
    -font-weight: 600;
}
.btn_disabled {
    cursor: default;
    background-color: rgba(0, 0, 0, 0.04);
}
.btn_disabled:hover {
    background-color: rgba(0, 0, 0, 0.04);
}
.MuiButton-root {
    text-transform: none !important;
}
.MuiButton-label {
    -webkit-transition: color 0.01s;
    transition: color 0.01s;
}
.p_0 {
    padding: 0 !important;
}
.pl_0 {
    padding-left: 0 !important;
}
.p_5 {
    padding: 5px;
}
.p_8 {
    padding: 8px;
}
.p_10 {
    padding: 10px;
}
.p_15 {
    padding: 15px;
}
.p_20 {
    padding: 20px;
}
.p_25 {
    padding: 25px;
}
.p_40 {
    padding: 40px;
}
.p_100 {
    padding: 100px;
}
.pb_0 {
    padding-bottom: 0;
}
.pb_5 {
    padding-bottom: 5px;
}
.pb_10 {
    padding-bottom: 10px;
}
.pb_15 {
    padding-bottom: 15px;
}
.pb_20 {
    padding-bottom: 20px;
}
.pb_40 {
    padding-bottom: 40px;
}
.pt_0 {
    padding-top: 0;
}
.pt_5 {
    padding-top: 5px;
}
.pt_10 {
    padding-top: 10px;
}
.pl_10 {
    padding-left: 10px;
}
.pl_20 {
    padding-left: 20px;
}
.pl_40 {
    padding-left: 40px;
}
.pt_20 {
    padding-top: 20px;
}
.pt_40 {
    padding-top: 40px;
}
.pt_60 {
    padding-top: 80px;
}
.pr_10 {
    padding-right: 10px;
}
.pr_12 {
    padding-right: 12px;
}
.pr_20 {
    padding-right: 20px;
}
.pr_40 {
    padding-right: 40px;
}
.ph_3 {
    padding-left: 3px;
    padding-right: 3px;
}
.ph_5 {
    padding-left: 5px;
    padding-right: 5px;
}
.ph_10 {
    padding-left: 10px;
    padding-right: 10px;
}
.ph_15 {
    padding-left: 15px;
    padding-right: 15px;
}
.ph_20 {
    padding-left: 20px;
    padding-right: 20px;
}
.ph_30 {
    padding-left: 30px;
    padding-right: 30px;
}
.ph_40 {
    padding-left: 40px;
    padding-right: 40px;
}
.pv_2 {
    padding-top: 2px;
    padding-bottom: 2px;
}
.pv_5 {
    padding-top: 5px;
    padding-bottom: 5px;
}
.pv_10 {
    padding-top: 10px;
    padding-bottom: 10px;
}
.pv_15 {
    padding-top: 15px;
    padding-bottom: 15px;
}
.pv_20 {
    padding-top: 20px;
    padding-bottom: 20px;
}
.pv_40 {
    padding-top: 40px;
    padding-bottom: 40px;
}
.gap_10 {
    gap: 10px;
}
.gap_20 {
    gap: 20px;
}
.gap_30 {
    gap: 30px;
}
.gap_40 {
    gap: 40px;
}
.gap_50 {
    gap: 50px;
}
.gap_100 {
    gap: 100px;
}
.gap_120 {
    gap: 120px;
}
.gap_15 {
    gap: 15px;
}
.gap_5 {
    gap: 5px;
}
.link {
    font-weight: 600;
    text-decoration: none;
    color: #004378;
    color: var(--color-primary);
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}
.link:hover {
        color: #072446;
        color: var(--color-primary-darker);
    }
a {
    color: #004378;
    color: var(--color-primary);
    text-decoration: none;
}
a:visited,
a:active,
a:focus,
a:link {
    color: #004378;
    color: var(--color-primary);
}
a:hover {
    color: #1670bf;
    color: var(--color-primary-lighter);
}
.m_auto {
    margin-left: auto;
    margin-right: auto;
}
.m_0 {
    margin: 0 !important;
}
.m_5 {
    margin: 5px !important;
}
.m_10 {
    margin: 10px !important;
}
.m_20 {
    margin: 20px !important;
}
.mh_5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
}
.mh_10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}
.mh_20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
}
.mh_30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
}
.mb_1 {
    margin-bottom: 1px !important;
}
.mb_3 {
    margin-bottom: 3px !important;
}
.mb_5 {
    margin-bottom: 5px !important;
}
.mb_10 {
    margin-bottom: 10px !important;
}
.mb_15 {
    margin-bottom: 15px !important;
}
.mb_20 {
    margin-bottom: 20px !important;
}
.mb_25 {
    margin-bottom: 25px !important;
}
.mb_30 {
    margin-bottom: 30px !important;
}
.mb_40 {
    margin-bottom: 40px !important;
}
.mb_50 {
    margin-bottom: 50px !important;
}
.mb_60 {
    margin-bottom: 60px !important;
}
.mb_80 {
    margin-bottom: 80px !important;
}
.ml_0 {
    margin-left: 0 !important;
}
.ml_5 {
    margin-left: 5px !important;
}
.ml_8 {
    margin-left: 8px !important;
}
.ml_10 {
    margin-left: 10px !important;
}
.ml_15 {
    margin-left: 15px !important;
}
.ml_20 {
    margin-left: 20px !important;
}
.ml_25 {
    margin-left: 25px !important;
}
.ml_40 {
    margin-left: 40px !important;
}
.mr_3 {
    margin-right: 3px !important;
}
.mr_5 {
    margin-right: 5px !important;
}
.mr_10 {
    margin-right: 10px !important;
}
.mr_15 {
    margin-right: 15px !important;
}
.mr_16 {
    margin-right: 16px !important;
}
.mr_20 {
    margin-right: 20px !important;
}
.mr_40 {
    margin-right: 40px !important;
}
.mr_60 {
    margin-right: 60px !important;
}
.mr_80 {
    margin-right: 80px !important;
}
.mr_120 {
    margin-right: 120px !important;
}
.mt_1 {
    margin-top: 1px !important;
}
.mt_3 {
    margin-top: 3px !important;
}
.mt_5 {
    margin-top: 5px !important;
}
.mt_7 {
    margin-top: 7px !important;
}
.mt_8 {
    margin-top: 8px !important;
}
.mt_10 {
    margin-top: 10px !important;
}
.mt_15 {
    margin-top: 15px !important;
}
.mt_20 {
    margin-top: 20px !important;
}
.mt_25 {
    margin-top: 25px !important;
}
.mt_30 {
    margin-top: 30px !important;
}
.mt_40 {
    margin-top: 40px !important;
}
.mt_50 {
    margin-top: 50px !important;
}
.mt_60 {
    margin-top: 60px !important;
}
.mt_80 {
    margin-top: 80px !important;
}
.mt_100 {
    margin-top: 100px !important;
}
.mv_5 {
    margin-top: 5px;
    margin-bottom: 5px;
}
.mv_10 {
    margin-top: 10px;
    margin-bottom: 10px;
}
.mv_20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}
.mv_30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}
.mv_40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}
.mv_60 {
    margin-top: 60px;
    margin-bottom: 60px;
}
.mv_120 {
    margin-top: 120px;
    margin-bottom: 120px;
}
.gap_5 {
    gap: 5px;
}
.gap_10 {
    gap: 10px;
}
.space-y-20 > * + * {
    margin-top: 20px;
}
.space-y-30 > * + * {
    margin-top: 30px;
}
.makeItAContainer {
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 0;
    padding-right: 4px;
}
.cursor_pointer {
    cursor: pointer;
}
.userSelect_none {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.position_relative {
    position: relative;
}
.position_absolute {
    position: absolute;
}
.position_fixed {
    position: fixed;
}
.transform_rotateMinus90 {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}
.transform_rotatePlus90 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.overflow_auto {
    overflow: auto;
}
.overflow-y_auto {
    overflow-y: auto;
}
.overflow_hidden {
    overflow: hidden;
}
.height_full {
    height: 100%;
}
.display_block {
    display: block !important;
}
.border_left {
    border-left: 1px;
    border-left-color: rgba(0, 0, 0, 0.2);
    border-left-style: solid;
}
.border_small {
    border: 1px solid rgba(0, 0, 0, 0.2);
}
.maxWidth_label {
    max-width: 200px;
}
.right_100 {
    right: 100px;
}
.redDot,
.greenDot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: red;
}
.greenDot {
    background-color: green;
}
@media (min-width: 600px) {
    .hiddenDesktop {
        display: none;
    }
}
@media (max-width: 600px) {
    .hiddenMobile {
        display: none;
    }
}
@media (min-width: 1280px) {
    .hiddenMediumDesktop {
        display: none;
    }
}
@media (max-width: 1280px) {
    .hiddenMediumMobile {
        display: none;
    }
}
.fontWeight_semi {
    font-weight: 400;
}
.fontWeight_normal {
    font-weight: 500;
}
.fontWeight_bold {
    font-weight: 600 !important;
}
.text-2xl {
    font-size: 20px;
}
.text_big {
    font-size: 18px;
}
.text_normal {
    font-size: 17px;
    font-weight: 500;
}
.text_small {
    font-size: 14px;
}
.text_xsmall {
    font-size: 12px;
}
.text_xxsmall {
    font-size: 10px;
}
.text_small_semiBold {
    font-size: 14px;
    font-weight: 600;
}
.text_bold {
    font-weight: 800 !important;
}
.text_upperCase {
    text-transform: uppercase;
}
.text_italic {
    font-style: italic;
}
.strikethrough {
    text-decoration: line-through;
}
.text_discreet {
    font-size: 14px;
    opacity: 0.7;
}
.text_primary {
    color: #004378 !important;
    color: var(--color-primary) !important;
}
.text_secondary {
    color: #ffc435 !important;
    color: var(--color-secondary) !important;
}
.text_secondary_darker {
    color: #b4880e !important;
    color: var(--color-secondary-darker) !important;
}
.text_error {
    color: #ff3e1d !important;
    color: var(--color-error) !important;
}
.text_black {
    color: black !important;
}
.text_warning {
    color: #ffc435 !important;
    color: var(--color-warning) !important;
}
.textAlign_right {
    text-align: right;
}
.textAlign_center {
    text-align: center;
}
.textOverflow_ellipsis {
    text-overflow: ellipsis;
}
.text_ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.text_over-lined {
    text-decoration: line-through;
}
.text_noWrap {
    white-space: nowrap;
}
.text_capitalize:first-letter {
    text-transform: uppercase;
}
.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
}
.h1 {
    font-size: 48px;
    font-weight: 600;
}
.h2 {
    font-size: 32px;
    font-weight: 600;
}
@media (max-width: 780px) {
    .h1 {
        font-size: 24px;
    }

    .h2 {
        font-size: 18px;
    }

    h1 {
        font-size: 24px;
    }

    h2 {
        font-size: 20px;
    }
}
.fadeIn {
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
}
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.slideTop50 {
    -webkit-animation: slide-top-50 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top-50 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-top-50 {
    from {
        -webkit-transform: translateY(-20%);
        transform: translateY(-20%);
        -webkit-transition-duration: 1s;
                transition-duration: 1s;
        -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
        opacity: 0.5;
    }
    100% {
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        -webkit-transition-duration: 1s;
                transition-duration: 1s;
        -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
        opacity: 1;
    }
}
@keyframes slide-top-50 {
    from {
        -webkit-transform: translateY(-20%);
        transform: translateY(-20%);
        -webkit-transition-duration: 1s;
                transition-duration: 1s;
        -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
        opacity: 0.5;
    }
    100% {
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        -webkit-transition-duration: 1s;
                transition-duration: 1s;
        -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
        opacity: 1;
    }
}
#unitGallery .swiper-button-next,
    #unitGallery .swiper-button-prev,
    #unitCard .swiper-button-next,
    #unitCard .swiper-button-prev,
    #unitFloorPlans .swiper-button-next,
    #unitFloorPlans .swiper-button-prev,
    #propertyImages .swiper-button-next,
    #propertyImages .swiper-button-prev,
    #propertyGallery .swiper-button-next,
    #propertyGallery .swiper-button-prev,
    #propertyFloorPlans .swiper-button-next,
    #propertyFloorPlans .swiper-button-prev,
    #propertySimilarProperties .swiper-button-next,
    #propertySimilarProperties .swiper-button-prev {
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
    }
#unitGallery .swiper-button-next,
    #unitGallery .swiper-button-prev {
        top: 50% !important;
        width: 30px !important;
    }
#unitGallery .swiper-button-prev, #unitCard .swiper-button-prev, #unitFloorPlans .swiper-button-prev, #propertyImages .swiper-button-prev, #propertyGallery .swiper-button-prev, #propertyFloorPlans .swiper-button-prev, #featuredProperties .swiper-button-prev, #featuredPropertiesSrps .swiper-button-prev, #propertySimilarProperties .swiper-button-prev {
        -webkit-transform: rotate(180deg) !important;
                transform: rotate(180deg) !important;
    }
#unitGallery .swiper-button-prev-featured, #unitCard .swiper-button-prev-featured, #unitFloorPlans .swiper-button-prev-featured, #propertyImages .swiper-button-prev-featured, #propertyGallery .swiper-button-prev-featured, #propertyFloorPlans .swiper-button-prev-featured, #featuredProperties .swiper-button-prev-featured, #featuredPropertiesSrps .swiper-button-prev-featured, #propertySimilarProperties .swiper-button-prev-featured {
        -webkit-transform: rotate(180deg) !important;
                transform: rotate(180deg) !important;
    }
#unitGallery .swiper-button-prev-banner, #unitCard .swiper-button-prev-banner, #unitFloorPlans .swiper-button-prev-banner, #propertyImages .swiper-button-prev-banner, #propertyGallery .swiper-button-prev-banner, #propertyFloorPlans .swiper-button-prev-banner, #featuredProperties .swiper-button-prev-banner, #featuredPropertiesSrps .swiper-button-prev-banner, #propertySimilarProperties .swiper-button-prev-banner {
        -webkit-transform: rotate(180deg) !important;
                transform: rotate(180deg) !important;
    }
.swiper-button-prev-assistant {
    -webkit-transform: rotate(180deg) !important;
            transform: rotate(180deg) !important;
}
#unitGallery .swiper-button-next::after,
    #unitGallery .swiper-button-prev::after,
    #unitCard .swiper-button-next::after,
    #unitCard .swiper-button-prev::after,
    #unitFloorPlans .swiper-button-next::after,
    #unitFloorPlans .swiper-button-prev::after,
    #propertyFloorPlans .swiper-button-next::after,
    #propertyFloorPlans .swiper-button-prev::after,
    #premiumPropertyImg .swiper-button-next::after,
    #premiumPropertyImg .swiper-button-prev::after,
    #featuredProperties .swiper-button-next::after,
    #featuredProperties .swiper-button-prev::after,
    #featuredPropertiesSrps .swiper-button-next::after,
    #featuredPropertiesSrps .swiper-button-prev::after,
    #propertySimilarProperties .swiper-button-next::after,
    #propertySimilarProperties .swiper-button-prev::after,
    #propertyImages .swiper-button-next::after,
    #propertyImages .swiper-button-prev::after,
    #propertyGallery .swiper-button-next::after,
    #propertyGallery .swiper-button-prev::after {
        display: none !important;
    }
#unitGallery:hover .swiper-button-next,
    #unitGallery:hover .swiper-button-prev,
    #unitCard:hover .swiper-button-next,
    #unitCard:hover .swiper-button-prev {
        opacity: 1 !important;
    }
#unitFloorPlans .swiper-button-next,
    #unitFloorPlans .swiper-button-prev,
    #propertyFloorPlans .swiper-button-next,
    #propertyFloorPlans .swiper-button-prev,
    #propertyImages .swiper-button-next,
    #propertyImages .swiper-button-prev,
    #propertySimilarProperties .swiper-button-next,
    #propertySimilarProperties .swiper-button-prev {
        opacity: 1;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
#propertySimilarProperties .swiper-button-next,
    #propertySimilarProperties .swiper-button-prev {
        top: 10px;
    }
#propertyImages .swiper-wrapper {
        -webkit-box-align: center !important;
            -ms-flex-align: center !important;
                align-items: center !important;
    }
#propertyImagesId .MuiDialog-paper, #propertyPageId .MuiDialog-paper {
        background-color: rgba(0, 0, 0, 0.85) !important;
        -webkit-box-shadow: none !important;
                box-shadow: none !important;
    }
#propertyPageId .MuiDialog-paper {
        border-radius: unset !important;
    }
/*.swiper-button-disabled {*/
/*    opacity: 0 !important;*/
/*}*/
#ctaGalleryPropertyPage .MuiDialog-paper {
        background-color: #f5f6fa !important;
        -webkit-box-shadow: none !important;
                box-shadow: none !important;
    }
#premiumPropertyImg .swiper-pagination {
        bottom: 5%;
    }
#premiumPropertyImg .swiper-pagination-bullet {
        z-index: 5;
        height: 10px;
        width: 10px;
        background-color: white;
        border-radius: 1px;
        opacity: 1;
        margin-left: 7px;
        margin-right: 7px;
        -webkit-box-shadow: 0 2px 2px rgba(35, 59, 70, 0.25);
                box-shadow: 0 2px 2px rgba(35, 59, 70, 0.25);
    }
#premiumPropertyImg .swiper-pagination-bullet-active {
        background-color: #ffc435;
        background-color: var(--color-secondary);
    }
.swiper-button-prev-assistant,
.swiper-button-next-assistant {
    height: 33px;
    width: 33px;
    cursor: pointer;
}
#featuredPropertiesSrps .swiper-button-next-featured,
    #featuredPropertiesSrps .swiper-button-prev-featured {
        height: 33px;
        width: 33px;
        cursor: pointer;
    }
@media (max-width: 1280px) {
        #featuredProperties .swiper-button-next-featured {
            right: -10px;
            width: 20px;
        }
        #featuredProperties .swiper-button-prev-featured {
            left: -10px;
            width: 20px;
        }
}
@media (max-width: 980px) {
        #featuredProperties .swiper-button-next-featured {
            right: -5px;
            width: 14px;
        }
        #featuredProperties .swiper-button-prev-featured {
            left: -5px;
            width: 14px;
        }
        #propertyImages .swiper-button-prev,
        #propertyImages .swiper-button-next {
            background-size: 60px !important;
        }

    #propertyImages .swiper-button-prev {
        height: 60px !important;
        width: 42px !important;
    }

    #propertyImages .swiper-button-next {
        height: 60px !important;
        width: 42px !important;
    }
}
#galleriesTabs::-webkit-scrollbar {
    width: 10px;
    background-color: #c1c1c1;
}
#galleriesTabs::-webkit-scrollbar-thumb {
    /*background-color: var(--color-secondary);*/
    border: solid 1px transparent;
    border-radius: 5px;
    border-radius: var(--border-radius);
}
::-webkit-scrollbar {
    width: 15px;
    background-color: #f1f1f1;
}
::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border: solid 1px #c1c1c1;
    background-clip: padding-box;
}
::-webkit-scrollbar-button:single-button {
    background-color: transparent;
    display: block;
    background-size: 10px;
    background-repeat: no-repeat;
}
::-webkit-scrollbar-button:single-button:vertical:decrement {
    height: 12px;
    width: 16px;
    background-position: center 4px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
}
::-webkit-scrollbar-button:single-button:vertical:increment {
    height: 12px;
    width: 16px;
    background-position: center 2px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
}
#searchBarResult ::-webkit-scrollbar {
        border-bottom-right-radius: 6px;
        border-bottom-right-radius: var(--border-radius-filters);
    }
#searchBarResult ::-webkit-scrollbar-thumb {
        border-bottom-right-radius: 6px;
        border-bottom-right-radius: var(--border-radius-filters);
    }
@media (max-width: 680px) {
    ::-webkit-scrollbar {
        display: none;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;
    }
}
#blogPage h1,
    #blogPage h2,
    #blogPage h3,
    #blogPage h4,
    #blogPage h5,
    #blogPage h6 {
        margin: 0;
        padding: 0.5rem;
    }
#propertySimilarProperties .swiper-button-next,
    #propertySimilarProperties .swiper-button-prev,
    #unitFloorPlans .swiper-button-next,
    #unitFloorPlans .swiper-button-prev,
    #propertyFloorPlans .swiper-button-next,
    #propertyFloorPlans .swiper-button-prev {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzMS40OSAzMS40OSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzEuNDkgMzEuNDk7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIHN0eWxlPSJmaWxsOiMwMTNhNjc7IiBkPSJNMjEuMjA1LDUuMDA3Yy0wLjQyOS0wLjQ0NC0xLjE0My0wLjQ0NC0xLjU4NywwYy0wLjQyOSwwLjQyOS0wLjQyOSwxLjE0MywwLDEuNTcxbDguMDQ3LDguMDQ3SDEuMTExDQoJQzAuNDkyLDE0LjYyNiwwLDE1LjExOCwwLDE1LjczN2MwLDAuNjE5LDAuNDkyLDEuMTI3LDEuMTExLDEuMTI3aDI2LjU1NGwtOC4wNDcsOC4wMzJjLTAuNDI5LDAuNDQ0LTAuNDI5LDEuMTU5LDAsMS41ODcNCgljMC40NDQsMC40NDQsMS4xNTksMC40NDQsMS41ODcsMGw5Ljk1Mi05Ljk1MmMwLjQ0NC0wLjQyOSwwLjQ0NC0xLjE0MywwLTEuNTcxTDIxLjIwNSw1LjAwN3oiLz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K);
    }
#featuredProperties .swiper-button-prev-featured,
    #featuredProperties .swiper-button-next-featured {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNiAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik00LjQyNjIgMS4wMDAwMUw4Ljg1MjQgNS40MjYyMUw0LjQyNjIgOS44NTI0MSIgc3Ryb2tlPSIjNzA3MDcwIiBzdHJva2Utd2lkdGg9IjIiLz4KICAgIDxwYXRoIGQ9Ik05LjYxOTU2IDEuMDAwMDFMMTQuMDQ1OCA1LjQyNjIxTDkuNjE5NTYgOS44NTI0MSIgc3Ryb2tlPSIjNzA3MDcwIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+);
    }
#featuredPropertiesSrps .swiper-button-prev-featured,
    #featuredPropertiesSrps .swiper-button-next-featured {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCAzMyAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxjaXJjbGUgY3g9IjE2LjUiIGN5PSIxNi41IiByPSIxNi41IiB0cmFuc2Zvcm09InJvdGF0ZSgtMTgwIDE2LjUgMTYuNSkiIGZpbGw9IiNGQ0NBM0UiLz4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTQuMDg0OCAyMS41MjE3TDE1LjU4MjMgMjNMMjIgMTYuNjY0NUwxNS41ODIzIDEwLjMyOTFMMTQuMDg0OCAxMS44MDc0TDE5LjAwNTEgMTYuNjY0NUwxNC4wODQ4IDIxLjUyMTdaIiBmaWxsPSIjMDA0Mzc4Ii8+Cjwvc3ZnPgo=);
    }
.swiper-button-prev-assistant,
.swiper-button-next-assistant {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCAzMyAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxjaXJjbGUgY3g9IjE2LjUiIGN5PSIxNi41IiByPSIxNi41IiB0cmFuc2Zvcm09InJvdGF0ZSgtMTgwIDE2LjUgMTYuNSkiIGZpbGw9IiMwMDQzNzgiLz4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTQuMDg0OCAyMS41MjE3TDE1LjU4MjMgMjNMMjIgMTYuNjY0NUwxNS41ODIzIDEwLjMyOTFMMTQuMDg0OCAxMS44MDc0TDE5LjAwNTEgMTYuNjY0NUwxNC4wODQ4IDIxLjUyMTdaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K);
}
#unitCard .swiper-button-next,
    #unitCard .swiper-button-prev,
    #propertyImages .swiper-button-next,
    #propertyImages .swiper-button-prev {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik04LjcxOTM0IDE4Ljc4MDhDOC41Nzg4OSAxOC42NDAyIDguNSAxOC40NDk1IDguNSAxOC4yNTA4QzguNSAxOC4wNTIgOC41Nzg4OSAxNy44NjE0IDguNzE5MzQgMTcuNzIwOEwxNC40MzkzIDEyLjAwMDhMOC43MTkzNCA2LjI4MDhDOC41ODY5MyA2LjEzODU0IDguNTE0ODMgNS45NTA0OSA4LjUxODE4IDUuNzU2MTdDOC41MjE1NCA1LjU2MTg1IDguNjAwMSA1LjM3NjQgOC43MzczNCA1LjIzODhDOC44NzQ5NCA1LjEwMTU2IDkuMDYwMzkgNS4wMjMgOS4yNTQ3MSA1LjAxOTY0QzkuNDQ5MDMgNS4wMTYyOSA5LjYzNzA4IDUuMDg4MzkgOS43NzkzNCA1LjIyMDhMMTYuMDI5MyAxMS40NzA4QzE2LjE2OTggMTEuNjExNCAxNi4yNDg3IDExLjgwMiAxNi4yNDg3IDEyLjAwMDhDMTYuMjQ4NyAxMi4xOTk1IDE2LjE2OTggMTIuMzkwMiAxNi4wMjkzIDEyLjUzMDhMOS43NzkzNCAxOC43ODA4QzkuNjM4NzEgMTguOTIxMyA5LjQ0ODA5IDE5LjAwMDEgOS4yNDkzNCAxOS4wMDAxQzkuMDUwNTkgMTkuMDAwMSA4Ljg1OTk3IDE4LjkyMTMgOC43MTkzNCAxOC43ODA4WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
    }
#propertyImages .swiper-button-prev,
    #propertyImages .swiper-button-next {
        background-size: 100px;
        height: 100px;
        width: 62px;
    }
#propertyImages .swiper-button-next {
        margin-right: 20px;
    }
#propertyImages .swiper-pagination-bullet {
        background-color: #d9d9d9;
    }
#propertyImages .swiper-pagination-bullet-active {
        background-color: white;
    }
#unitGallery .swiper-button-next,
    #unitGallery .swiper-button-prev,
    #propertyGallery .swiper-button-next,
    #propertyGallery .swiper-button-prev {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0xMy41NTQ1IDEyLjA5NTNMMTMuNTU4NCAxMi4wNjQ0TDEzLjU1ODQgMTIuMDMzM0wxMy41NTg0IDExLjkzMzRMMTMuNTU4NCAxMS45MDIzTDEzLjU1NDUgMTEuODcxNUMxMy41MTA4IDExLjUyMTMgMTMuMzc0NCAxMS4xNzU3IDEzLjExMTkgMTAuOTEzMkMxMy4xMTE5IDEwLjkxMzIgMTMuMTExOSAxMC45MTMyIDEzLjExMTkgMTAuOTEzMkwzLjE0NTE4IDAuOTQ2NTA4QzIuNTUyMjUgMC4zNTM1ODEgMS41NzQ0OSAwLjM1MTExMSAwLjk3ODU1MyAwLjkzOTQ2N0MwLjMzMDA0MSAxLjU0NTcgMC4zNTMzNDYgMi41MzU1MSAwLjk3MTQ1MyAzLjE1MzY3TDAuOTcxNDY5IDMuMTUzNjlMOS44MTc3OCAxMkwwLjk3NzgxMyAyMC44NEMwLjM0MDQ5MiAyMS40MzY5IDAuMzQwNzMzIDIyLjQ2MzkgMC45Nzg1MzQgMjMuMDYwNUMxLjU3NDUxIDIzLjY0ODggMi41NTIyOCAyMy42NDY1IDMuMTQ1MTggMjMuMDUzNUMzLjE0NTIgMjMuMDUzNSAzLjE0NTIyIDIzLjA1MzQgMy4xNDUyNSAyMy4wNTM0TDEzLjExMTkgMTMuMDg2OEMxMy4zODU0IDEyLjgxMzIgMTMuNTEzNCAxMi40MjQ2IDEzLjU1NDUgMTIuMDk1M1oiIGZpbGw9IndoaXRlIiBzdHJva2U9IndoaXRlIi8+Cjwvc3ZnPgo=);
        background-size: 15px !important;
        height: 100%;
        top: 22px !important;
    }
#propertyGallery .swiper-container.swiper-container-initialized {
    height: unset !important;
}
* {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
html,
body {
    height: 100%;
}
body {
    width: 100%;
    font-family: "Poppins", sans-serif;
    min-height: 100%;
    color: #004378;
    color: var(--color-text);
    background-color: #fff;
    background-color: var(--color-firstLvl-bg);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
#app {
    min-height: 100vh;
}
hr {
    background-color: #e0e0e0;
    background-color: var(--color-firstLvl-bg-dark);
    height: 1px;
    border: none;
}
textarea {
    white-space: pre-wrap;
}
p {
    margin: 0;
    line-height: 1.85;
    white-space: normal;
    font-size: 17px;
    color: #111;
    color: var(--color-font);
    padding: 0.5rem;
}
li {
    line-height: 2;
    color: #111;
    color: var(--color-font);
}
ul,
ol {
    margin: 0;
    white-space: normal;
    color: #111;
    color: var(--color-font);
}
img {
    max-width: 100%;
}
.hidden {
    display: none !important;
}
.shadow {
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
}
h1,
h2,
h3,
h4 {
    color: #004378;
    color: var(--color-primary);
}
#searchPage #paginator ul .MuiPaginationItem-icon {
    background-color: red;
    display: none;
}
#searchPage #paginator ul li:last-child {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNyA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yLjMxMTMgOC43MTY4N0w2LjE4ODMzIDUuMTg0ODlDNi4xODgzMyA1LjE4NDMzIDYuMTg4OTMgNS4xODQzMyA2LjE4OTUzIDUuMTgzNzdDNi42MDM0OSA0LjgwNjI2IDYuNjAzNDkgNC4xOTQxNiA2LjE4OTUzIDMuODE2NjZMMi4zMTEzIDAuMjgzNTQ4QzEuODk2NzQgLTAuMDk0NTE2OCAxLjIyNDQzIC0wLjA5NDUxNjggMC44MTA0NjkgMC4yODM1NDhDMC4zOTY1MSAwLjY2MTA1MSAwLjM5NjUxIDEuMjczMTYgMC44MTA0NjkgMS42NTEyMkwzLjkzODI5IDQuNTAwMjFMMC44MTA0NjkgNy4zNDkyQzAuMzk2NTEgNy43MjY3IDAuMzk2NTEgOC4zMzkzNyAwLjgxMDQ2OSA4LjcxNjg3QzEuMjI0NDMgOS4wOTQzOCAxLjg5Njc0IDkuMDk0MzggMi4zMTEzIDguNzE2ODdaIiBmaWxsPSIjMDA0Mzc4Ii8+Cjwvc3ZnPgo=);
    background-repeat: no-repeat;
    background-position: center;
}
#searchPage #paginator ul li:first-child {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNyA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjY4ODcgMC4yODMxMjdMMC44MTE2NzEgMy44MTUxMUMwLjgxMTY3MSAzLjgxNTY3IDAuODExMDY5IDMuODE1NjcgMC44MTA0NjkgMy44MTYyM0MwLjM5NjUxIDQuMTkzNzQgMC4zOTY1MSA0LjgwNTg0IDAuODEwNDY5IDUuMTgzMzRMNC42ODg3IDguNzE2NDVDNS4xMDMyNiA5LjA5NDUyIDUuNzc1NTcgOS4wOTQ1MiA2LjE4OTUzIDguNzE2NDVDNi42MDM0OSA4LjMzODk1IDYuNjAzNDkgNy43MjY4NCA2LjE4OTUzIDcuMzQ4NzhMMy4wNjE3MSA0LjQ5OTc5TDYuMTg5NTMgMS42NTA4QzYuNjAzNDkgMS4yNzMzIDYuNjAzNDkgMC42NjA2MjkgNi4xODk1MyAwLjI4MzEyN0M1Ljc3NTU3IC0wLjA5NDM3NTYgNS4xMDMyNiAtMC4wOTQzNzU2IDQuNjg4NyAwLjI4MzEyN1oiIGZpbGw9IiMwMDQzNzgiLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    background-position: center;
}
#searchPage #paginator ul li a button {
    color: rgba(0, 67, 120, 0.5) !important;
    font-size: 16px;
}
#searchPage #paginator ul li a .Mui-selected {
    color: #004378 !important;
    color: var(--color-primary) !important;
    background: none;
    font-weight: 600 !important;
}
#searchPage #paginator ul li button:hover {
    background: none;
}
.MuiTab-root {
    font-family: "Poppins", sans-serif !important;
}
/* Google Maps */
.gm-style .gm-style-iw-t:after {
    display: none !important;
}
.gm-style-iw-chr {
    height: 0 !important;
    padding: 0 !important;
    display: none !important;
}
.gm-style .gm-style-iw-c {
    padding: 0 !important;
    border: 0 !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    max-width: 400px !important;
    max-height: 727px !important;
    min-width: 0 !important;
    margin-left: 20px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.gm-ui-hover-effect {
    display: none !important;
}
.gm-style-iw,
.gm-style-iw-c {
    border: none !important;
}
.gm-style-iw-d {
    width: 100% !important;
    height: 100% !important;
    max-height: 500px !important;
    overflow: hidden !important;
    border: none !important;
}
.gm-style .gm-style-iw-tc {
    display: none;
    background: none !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}
#js-wpv-addon-maps-render-map-5 div[style*="cursor: default"] {
    border: 2px solid red;
    overflow: hidden;
}
/* Google Maps End*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}
#sliderRange .PrivateValueLabel-circle-4 {
    width: unset !important;
    height: unset !important;
    color: #004378 !important;
    color: var(--color-primary) !important;
    background: unset !important;
}
#sliderRange .MuiSlider-valueLabel {
    display: none;
}
#sliderRange .MuiSlider-rail {
    height: 4px !important;
}
#sliderRange .MuiSlider-track {
    height: 4px !important;
}
#contentSliderRange .MuiSlider-root {
    padding: 0 !important;
}
.favoriteProperty .material-icons {
    font-size: 28px;
}
#mobileFilters .MuiButton-root {
    border: 1px solid #004378;
    border: 1px solid var(--color-primary);
}
#mobileFilters .MuiButton-label {
    color: #004378;
    color: var(--color-primary);
}
#mobileFilters .MuiButton-contained .MuiButton-label {
    color: white;
}
.grecaptcha-badge {
    visibility: hidden;
}
.aspectRatio {
    aspect-ratio: 4/3;
}
@media (max-width: 780px) {
    p {
        font-size: 16px;
    }
}
#propertiesSearchBar_filters .MuiButton-label {
    font-weight: 500 !important;
}
.swiper-container {
    max-height: 100% !important;
}
#blogPage p {
        padding: 0 0.5rem;
        margin: -5px 0 5px 0;
    }
